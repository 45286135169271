function nav() {
    let navEl = document.getElementById('jsNav'),
        navBtnEl = document.getElementById('jsNavBtn'),
        navCloseEl = document.getElementById('jsNavClose');
    if (navBtnEl) {
        navBtnEl.addEventListener('click', function() {
            if (navEl.classList.contains('show')) {
                navEl.classList.remove('show');
            } else {
                navEl.classList.add('show');
            }
        });
    }
    if (navCloseEl) {
        navCloseEl.addEventListener('click', function() {
            navEl.classList.remove('show');
        });
    }
}

window.addEventListener('load', function() {
    nav();
});