let lightboxEl,
  lightboxCloseEl,
  lightboxAvatarEl,
  lightboxPrevEl,
  lightboxNextEl,
  lightboxIndex = 0,
  lightboxImgEls;

function fadeIn(el) {
  el.classList.remove("fade-out");
  el.classList.add("fade-in");
}

function fadeOut(el, src) {
  el.classList.remove("fade-in");
  el.classList.add("fade-out");
  setTimeout(() => {
    el.src = src;
    fadeIn(el);
  }, 250);
}

function removeClass(arr, className) {
  arr.forEach((element) => {
    element.classList.remove(className);
  });
}

function lightboxShow(el) {
  el.addEventListener("click", (e) => {
    lightboxImgEls = el.querySelectorAll(".jsLightboxImg");
    if (lightboxAvatarEl && lightboxImgEls) {
      lightboxEl.classList.add("show");
      lightboxIndex = 0;
      lightboxAvatarEl.src = lightboxImgEls[0].dataset.src;
      if(lightboxImgEls.length <= 1) {
        lightboxNextEl.classList.add("hidden");
        lightboxPrevEl.classList.add("hidden");
      }else {
        lightboxNextEl.classList.remove("hidden");
        lightboxPrevEl.classList.remove("hidden");        
      }
    }
  });
}
function lightboxInit() {
  if (callLightboxEls) {
    callLightboxEls.forEach((callLightboxEl) => {
      lightboxShow(callLightboxEl);
    });
  }

  if (lightboxEl) {
    lightboxEl.addEventListener("click", function (e) {
      if (e.target.id === "jsLightbox") {
        lightboxEl.classList.remove("show");
      }
    });
  }

  if (lightboxCloseEl) {
    lightboxCloseEl.addEventListener("click", (e) => {
      lightboxEl.classList.remove("show");
      lightboxIndex = 0;
    });
  }

  if (lightboxNextEl) {
    lightboxNextEl.addEventListener("click", (e) => {
      if (lightboxIndex < lightboxImgEls.length - 1) {
        lightboxIndex++;
      } else {
        lightboxIndex = 0;
      }
      fadeOut(lightboxAvatarEl, lightboxImgEls[lightboxIndex].dataset.src);
    });
  }

  if (lightboxPrevEl) {
    lightboxPrevEl.addEventListener("click", (e) => {
      if (lightboxIndex > 0) {
        lightboxIndex--;
      } else {
        lightboxIndex = lightboxImgEls.length - 1;
      }
      fadeOut(lightboxAvatarEl, lightboxImgEls[lightboxIndex].dataset.src);
    });
  }
}

window.addEventListener("load", (e) => {
  callLightboxEls = document.querySelectorAll(".jsCallLightbox");
  lightboxEl = document.getElementById("jsLightbox");
  lightboxCloseEl = document.getElementById("jsLightboxClose");
  lightboxAvatarEl = document.getElementById("jsLightboxAvatar");
  lightboxPrevEl = document.getElementById("jsLightboxPrev");
  lightboxNextEl = document.getElementById("jsLightboxNext");

  lightboxInit();
});
