function toTop() {
    toTopEl = document.getElementById('jsToTop');
    if (toTopEl) {
        if (window.scrollY >= 100) {
            toTopEl.classList.add('sticky');
        } else {
            toTopEl.classList.remove('sticky');
        }
    }
}

let timerScrollToTop,
    toTopEl = document.getElementById('jsToTop');
window.addEventListener('load', function() {
    if (toTopEl) {
        toTopEl.addEventListener('click', function(e) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    }
    toTop();
});

function timeoutScrollToTop() {
    toTop();
}

window.addEventListener('scroll', function() {
    clearTimeout(timerScrollToTop);
    timerScrollToTop = setTimeout(timeoutScrollToTop, 100);
});