window.addEventListener('load', function () {
  let anchorEls = document.querySelectorAll('.jsAnchor');
  anchorClick = (anchorEl) => {
    anchorEl.addEventListener('click', (e) => {
      let anchorTargetEl = document.getElementById(anchorEl.dataset.anchor);
      if (anchorTargetEl) {
        window.scrollTo({
          top: anchorTargetEl.offsetTop - 60,
          behavior: 'smooth',
        });
      }
      e.preventDefault();
    });
  };
  if (anchorEls.length > 0) {
    anchorEls.forEach((anchorEl) => {
      anchorClick(anchorEl);
    });
  }
});
